/* chatscope styles */

div .cs-message-list__typing-indicator-container div {
  color: #0F9190;
  }
  
  div .cs-main-container,
  div .cs-chat-container .cs-message-input {
  border: 1px;
  }
  
  div .cs-message--incoming .cs-message__content,
  div .ps__thumb-y,
  div .ps__rail-y:hover .ps__thumb-y,
  div .ps__rail-y:active .ps__thumb-y {
  background-color: #dddddd;
  }
  
  div .cs-chat-container .cs-message-input {
    background-color: #0F919066;
  }
  
  div .cs-message-input__content-editor,
  div .cs-message-input__content-editor-wrapper,
  div .cs-message-list,
  div .ps__rail-y:hover,
  div .ps .ps__rail-y:hover,
  div .cs-message-separator,
  div .ps__rail-y {
  background-color: #ffffff;
  }

  div .cs-message-separator {
    padding-top: 10px;
    color: #777;

    &::before, 
    &::after {
      background-color: #eee;
    }
  }


  div .cs-message--outgoing .cs-message__content {
  background-color: #0F919066;
  }
  
  div .cs-typing-indicator__dot {
  background-color: #0F9190;
  }
